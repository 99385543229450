














import axios from 'axios'
import EditorJS from '@editorjs/editorjs'
import Header from 'editorjs-header-with-anchor'
import ImageTool from '@editorjs/image'
// import ListTool from '@editorjs/list'
import ParagraphTool from '@editorjs/paragraph'
import TableTool from 'editorjs-table'
import Underline from '@editorjs/underline'
import WarningTool from '@editorjs/warning'
import AttachesTool from '@editorjs/attaches'
import Tooltip from 'editorjs-tooltip'
import Delimiter from '@editorjs/delimiter'
import Alert from 'editorjs-alert'
import NestedList from '@editorjs/nested-list'
import AlignmentTuneTool from 'editorjs-text-alignment-blocktune'
import ColorPlugin from 'editorjs-text-color-plugin'
import DragDrop from 'editorjs-drag-drop'
import Undo from 'editorjs-undo'
import Strikethrough from 'editorjs-strikethrough'
import anyButton from 'editorjs-button'
import editorHTML from 'editorjs-html'
import { omit } from 'lodash'
import { Bind, Debounce } from 'lodash-decorators'
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'

import SwitchInput from '@/components/_uikit/controls/SwitchInput.vue'
import NotifyMixin from '@/mixins/NotifyMixin'
import SystemModule from '@/store/modules/system'
import { MediaResource, IEditorData } from '@/store/types'
import { baseURL, getToken } from '@/utils/services/config'

import i18n from './i18n'

@Component({
  components: {
    SwitchInput,
  },
})
export default class TextEditor extends Mixins(NotifyMixin) {
  @Prop({ default: 'js-editor' })
  private id!: string

  @Prop({ default: null })
  private value!: IEditorData

  @Prop({ default: false })
  private borderTopNone!: boolean

  @Prop({
    default: 'MASTER',
    validator (value: string): boolean {
      return !!value.match(/(MASTER|MENTOR)/)
    },
  })
  private type!: 'MASTER' | 'MENTOR'

  // Включение только режима для чтения. Редактор при значении "true" не инициализируется
  @Prop({ default: false })
  private readonly!: boolean

  @Prop({ default: 'Начните писать' })
  private placeholder!: string

  @Prop({ default: false })
  private notPaddingY!: boolean

  private isPreview = false
  private parserToHTML = editorHTML({
    AnyButton: this.parserButton,
    alert: this.parserAlert,
    attaches: this.parserAttaches,
    delimiter: this.parseDelimiter,
    image: this.parserImage,
    list: this.parserList,
    paragraph: this.parserParagraph,
    table: this.parserTable,
    warning: this.parserWarning,
  })
  private parsedBlocks: string[] = []

  private get isMentor () {
    return this.type === 'MENTOR'
  }

  private mounted () {
    this.isPreview = this.readonly

    if (this.readonly) {
      this.parseDataToHTML(this.value)
    } else {
      this.initEditor()
    }
  }

  private initEditor () {
    SystemModule.loadingStart()

    const tools: any = {
      AnyButton: {
        class: anyButton,
        inlineToolbar: false,
      },
      Color: {
        class: ColorPlugin,
        config: {
          colorCollections: ['#ff922e', '#468ae6', '#60aaa9', '#e53935', '#a66eff', '#f25e94', '#000000de'],
          defaultColor: '#ff922e',
          type: 'text',
        },
      },
      Marker: {
        class: ColorPlugin, // if load from CDN, please try: window.ColorPlugin
        config: {
          colorCollections: ['#ffe4cb', '#d3e2ff', '#daeff0', '#ffcdd2', '#e9dfff', '#eee', 'transparent'],
          defaultColor: '#FFBF00',
          type: 'marker',
        },
      },
      alert: {
        class: Alert,
        config: {
          defaultType: 'primary',
          messagePlaceholder: 'Enter something',
        },
        inlineToolbar: true,
        shortcut: 'CMD+SHIFT+A',
        tunes: ['anyTuneName'],
      },
      anyTuneName: {
        class:AlignmentTuneTool,
        config:{
          blocks: {
            header: 'center',
            list: 'left',
          },
          default: 'left',
        },
      },
      attaches: {
        class: AttachesTool,
        config: {
          buttonText: 'Загрузить файл',
          uploader: {
            uploadByFile: this.uploadFile,
          },
        },
      },
      delimiter: Delimiter,
      header: {
        class: Header,
        config: {
          defaultLevel: 3,
          levels: [2, 3, 4],
          tunes: ['anyTuneName'],
        },
        inlineToolbar: true,
      },
      image: {
        class: ImageTool,
        config: {
          buttonContent: 'Загрузить изображение',
          captionPlaceholder: 'Подпись',
          uploader: {
            uploadByFile: this.uploadImage,
          },
        },
      },
      list: {
        class: NestedList,
        inlineToolbar: true,
        tunes: ['anyTuneName'],
      },
      paragraph: {
        class: ParagraphTool,
        inlineToolbar: true,
        tunes: ['anyTuneName'],
      },
      strikethrough: {
        class: Strikethrough,
        shortcut: 'CMD+SHIFT+X',
      },
      table: {
        class: TableTool,
      },
      tooltip: {
        class: Tooltip,
        config: {
          backgroundColor: '#154360',
          highlightColor: '#FFEFD5',
          holder: 'editorId',
          location: 'left',
          textColor: '#FDFEFE',
          underline: true,
        },
      },
      underline: Underline,
    }

    if (this.isMentor) {
      tools.warning = {
        class: WarningTool,
        config: {
          messagePlaceholder: 'Сообщение',
          titlePlaceholder: 'Заголовок',
        },
      }
    }

    const config = {
      data: this.value,
      holder: this.id,
      i18n,
      inlineToolbar: this.isMentor,
      onChange: this.handleSave,
      onReady: () => {
        new DragDrop(window.editor)
        new Undo({ editor: window.editor })
      },
      placeholder: this.placeholder,
      readOnly: this.readonly,
      tools,
    }

    window.editor = new EditorJS(this.value ? config : omit(config, ['data']))

    window.editor.isReady
      .then(() => {
        // При уничтожении компонента появляется ошибка "Failed to execute 'observe' on 'MutationObserver': parameter 1 is not of type 'Node'."
        // Временно убираем эту функцию
        // new UndoTool({ editor: window.editor })

        if (this.value) {
          window.editor.render(this.value)
        }
      })
      .catch(this.notifyError)
      .finally(() => {
        SystemModule.loadingEnd()
      })
  }

  private destroyed () {
    if (window.editor) {
      if (typeof window.editor.destroy === 'function') {
        window.editor.destroy()
      } else {
        SystemModule.loadingEnd()
      }
    }
  }

  private parserTable (block: any) {
    let table = '<div class="el-table"><table>'
    block.data.content.forEach((row: string[]) => {
      let innerContent = '<tr>'
      row.forEach(cell => innerContent += `<td>${cell}</td>`)
      innerContent += '</tr>'
      table += innerContent
    })
    return `${table}</table></div>`
  }

  private parserWarning (block: any) {
    return `<div class="el-warning">
      <div class="el-warning__title text-body-3">${block.data.title}</div>
      <div class="el-warning__text">${block.data.message}</div>
    </div>`
  }

  private parserImage(block: any) {
    return `<div class="el-image">
      <img class="el-image__tag" src="${block.data.file.url}" alt="${block.data.caption}"/>
      <div class="el-image__caption">${block.data.caption}</div>
    </div>`
  }

  private parserAttaches(block: any) {
    const dataSize = block.data.file.size > 1024 ** 2 ? 'MiB' : 'KiB'
    const size = dataSize === 'MiB' ? (block.data.file.size / 1024 / 1024).toFixed(2) : (block.data.file.size / 1024).toFixed(1)
    return `
      <div class="el-file">
        <div class="cdx-attaches cdx-attaches--with-file">
          <div class="cdx-attaches__file-icon" data-extension="${block.data.file.extension}" style="color: rgb(246, 118, 118);">
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="40">
              <path d="M17 0l15 14V3v34a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3h20-6zm0 2H3a1 1 0 0 0-1 1v34a1 1 0 0 0 1 1h26a1 1 0 0 0 1-1V14H17V2zm2 10h7.926L19 4.602V12z"></path>
            </svg>
          </div>
          <div class="cdx-attaches__file-info">
            <div class="cdx-attaches__title">${block.data.title}</div>
            <div class="cdx-attaches__size" data-size="${dataSize}">${size}</div>
          </div>
          <a class="cdx-attaches__download-button" href="${block.data.file.url}" target="_blank" rel="nofollow noindex noreferrer">
            <svg xmlns="http://www.w3.org/2000/svg" width="17pt" height="17pt" viewBox="0 0 17 17">
              <path d="M9.457 8.945V2.848A.959.959 0 0 0 8.5 1.89a.959.959 0 0 0-.957.957v6.097L4.488 5.891a.952.952 0 0 0-1.351 0 .952.952 0 0 0 0 1.351l4.687 4.688a.955.955 0 0 0 1.352 0l4.687-4.688a.952.952 0 0 0 0-1.351.952.952 0 0 0-1.351 0zM3.59 14.937h9.82a.953.953 0 0 0 .953-.957.952.952 0 0 0-.953-.953H3.59a.952.952 0 0 0-.953.953c0 .532.425.957.953.957zm0 0" fill-rule="evenodd"></path>
            </svg>
          </a>
        </div>
      </div>
    `
  }

  private parserParagraph(block: any) {
    return `<p class="el-paragraph" style="text-align: ${block.tunes?.anyTuneName.alignment};">${block.data.text}</p>`
  }

  private parserList(block: any) {
    return itemParse(block.data.items, block.data.style === 'ordered' ? 'ol' : 'ul')

    function itemParse(arr: any[], tag: string, children = false): string {
      return `<${tag} style="text-align: ${block.tunes?.anyTuneName.alignment}" class="cdx-nested-list cdx-nested-list--${block.data.style} ${children ? 'cdx-nested-list__item-children' : 'cdx-block'}">
        ${(arr).map(item => {
    return `
          <li class="cdx-nested-list__item">
            <div class="cdx-nested-list__item-body">
              <div class="cdx-nested-list__item-content-read">
                ${item.content || item}
              </div>
              ${item.items && item.items.length ? itemParse(item.items, tag, true) : ''}
            </div>
          </li>
        `
  }).join('')}
      </${tag}>`
    }
  }

  private parserAlert(block: any) {
    return `<div class="cdx-alert cdx-alert-${block.data.type}" style="text-align: ${block.tunes?.anyTuneName.alignment}">
        <div class="cdx-alert__message">${block.data.message}</div>
      </div>`
  }

  private parseDelimiter() {
    return '<div class="ce-delimiter cdx-block"></div>'
  }

  private parserButton(block: any) {
    return `<button class="v-btn v-btn__link v-btn--is-elevated v-btn--has-bg theme--light v-size--default primary">
        <a href="${block.data.link}" target="_blank" class="v-btn__content" style="color: #fff">${block.data.text}</a>
      </button>`
  }

  private parseDataToHTML (data: IEditorData) {
    const emptyData: IEditorData = {
      blocks: [],
      time: + new Date(),
      version: '',
    }
    this.parsedBlocks = [...this.parserToHTML.parse(data || emptyData)]
  }

  private uploadFile(file: File) {
    const formData = new FormData()
    const authToken = getToken()
    const headers: any = {
      'Accept': 'application/json',
      'Content-Type': 'multipart/form-data',
    }

    if (file.size > 10000 * 1024 * 1024) {
      this.notifyError('Максимальный размер файла 300kb')
      return Promise.reject({ file: { url: '' }, success: 0 })
    }

    formData.append('file', file)

    if (process.env.NODE_ENV !== 'production' && authToken) {
      headers.Authorization = `Bearer ${authToken}`
    }

    return axios.post(`${baseURL}/upload`, formData, { headers })
      .then(response => response.data)
      .then((response: MediaResource) => {
        return {
          file: {
            ...response,
            name: response.filename,
            title: response.filename,
          },
          success: 1,
        }
      })
      .catch((error: any) => {
        this.notifyError(error)
        return { file: { url: '' }, success: 0 }
      })
  }

  private uploadImage (file: File) {
    const formData = new FormData()
    const authToken = getToken()
    const headers: any = {
      'Accept': 'application/json',
      'Content-Type': 'multipart/form-data',
    }

    if (file.size > 300 * 1024) {
      this.notifyError('Максимальный размер файла 300kb')
      return Promise.reject({ file: { url: '' }, success: 0 })
    }

    formData.append('file', file)

    if (process.env.NODE_ENV !== 'production' && authToken) {
      headers.Authorization = `Bearer ${authToken}`
    }

    return axios.post(`${baseURL}/upload`, formData, { headers })
      .then(response => response.data)
      .then((response: MediaResource) => {
        return {
          file: response,
          success: 1,
        }
      })
      .catch((error: any) => {
        this.notifyError(error)
        return { file: { url: '' }, success: 0 }
      })
  }

  @Bind
  @Debounce(500)
  private handleSave () {
    if (window.editor) {
      if (typeof window.editor.save === 'function') {
        window.editor.save()
          .then((data: IEditorData) => {
            this.$emit('update:value', data)
          })
          .catch(this.notifyError)
      }
    }
  }

  @Watch('isPreview')
  private watchIsPreview () {
    if (window.editor) {
      if (typeof window.editor.save === 'function') {
        if (this.value) {
          window.editor.save()
            .then(this.parseDataToHTML)
            .catch(this.notifyError)
        }
      }
    }
  }

  @Watch('readonly')
  private watchReadonly () {
    this.isPreview = this.readonly
    if (this.value) {
      this.parseDataToHTML(this.value)
    }
  }
}
