export default {
  messages: {
    blockTunes: {
      deleteTune: { 'Delete': 'Удалить (2 клика)' },
      moveDownTune: { 'Move down': 'Переместить вниз' },
      moveUpTune: { 'Move up': 'Переместить вверх' },
    },
    toolNames: {
      Bold: 'Полужирный',
      Checklist: 'Чеклист',
      Code: 'Код',
      Delimiter: 'Разделитель',
      Heading: 'Заголовок',
      Image: 'Изображение',
      InlineCode: 'Моноширинный',
      Italic: 'Курсив',
      Link: 'Ссылка',
      List: 'Список',
      Marker: 'Маркер',
      Quote: 'Цитата',
      'Raw HTML': 'HTML-фрагмент',
      Table: 'Таблица',
      Text: 'Параграф',
      Underline: 'Подчеркивание',
      Warning: 'Примечание',
    },
    tools: {
      AnyButton: {
        'Button Text': 'Текст кнопки',
        'Default Button': 'Кнопка',
        'Link Url': 'Ссылка',
        'Set': 'Добавить',
      },
      link: {
        'Add a link': 'Вставьте ссылку',
      },
      stub: {
        'The block can not be displayed correctly.': 'Блок не может быть отображен',
      },
    },
    ui: {
      blockTunes: {
        toggler: {
          'Click to tune': 'Нажмите, чтобы настроить',
          'or drag to move': 'или перетащите',
        },
      },
      inlineToolbar: {
        converter: {
          'Convert to': 'Изменить',
        },
      },
      toolbar: {
        toolbox: {
          Add: 'Добавить',
        },
      },
    },
  },
}
